<template>
    <v-dialog class="custom-dialog" v-model="visible" width="90vw" max-width="800" persistent>
        <v-card>
            <v-card-title class="d-flex align-center justify-space-between mb-4">
                <div>
                    Order Items
                </div>
                <div class="d-flex align-center">
                    <div v-if="gift">
                        <v-chip variant="flat" color="primary">
                            Gift Wrap
                        </v-chip>
                    </div>
                    <div v-if="expressDelivery" class="mx-2">
                        <v-chip variant="flat" color="secondary">
                            Express Delivery
                        </v-chip>
                    </div>
                </div>

            </v-card-title>
            <v-card-text>

                <v-row v-for="item in order.line_items" :key="item.id">
                    <v-col>
                        <img class="item-img" :src="item.image.src" alt="item image">
                    </v-col>
                    <v-col>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 custom-text">{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle class="font-weight-black text-h6">SKU: {{ item.sku
                                }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="font-weight-black text-h6">Quantity: {{ item.quantity
                                }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-col>
                </v-row>
                <!-- Assuming you have image_url -->

                <v-row v-if="giftMessage">
                    <v-col>
                        <v-alert border="left" colored-border color="deep-purple accent-4" elevation="2">
                            <div class="text-h6">
                                Message
                            </div>
                            <div class="mt-2">
                                {{ customMessage }}
                            </div>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="close">{{ order.status == 'processing' ? 'Cancel' : 'Close' }}</v-btn>
                <v-btn v-if="order.status == 'processing'" color="primary" text @click="confirmPacking">Confirm
                    Packing</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['order', 'gift', 'close', "expressDelivery", "giftMessage", "customMessage"],
    data() {
        return {
            visible: false,
            // giftwrap: false,
        };
    },
    mounted() {



    },
    methods: {
        confirmPacking() {

            this.$emit('confirmPacking', this.order);
            this.visible = false;
        }
    }
};
</script>
<style scoped>
.item-img {
    width: 15rem;
    height: auto;
}

.custom-text {
    text-overflow: unset;
    white-space: wrap;
}
</style>