import Vue from "vue";
import VueRouter from "vue-router";
import Packaging from "../components/Packaging.vue";
import Release from "../components/Release.vue";
import Shipping from "../components/Shipping.vue";
import Home from "../views/HomeView.vue";

import store from "@/store/store";
import Login from "../components/Login.vue";
import ReleaseOther from "../components/ReleaseOther.vue";
import Reports from "../components/Reports.vue";
import ShippingDashboard from "../components/ShippingDashboard.vue";
import CreateListing from "../views/CreateListing";
import OtherListing from "../views/OtherListing";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    //     meta: {
    //   requiresAuth: true,
    //   allowedRoles: ["manager", "agent", "shipping"], // Specify allowed roles
    // }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "agent"], // Specify allowed roles
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: ShippingDashboard,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "shipping"], // Specify allowed roles
    }
  },
  {
    path: "/start",
    name: "Start",
    component: CreateListing,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "agent"], // Specify allowed roles
    }
  },
  {
    path: "/other",
    name: "Other",
    component: OtherListing,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "agent"], // Specify allowed roles
    }
  },

  {
    path: "/release/:mode/:id",
    name: "Release",
    component: Release,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "agent"], // Specify allowed roles
    }
  },
  {
    path: "/releaseother/:mode/:id",
    name: "Release-Other",
    component: ReleaseOther,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "agent"], // Specify allowed roles
    }
  },
  {
    path: "/packaging",
    name: "Packaging",
    component: Packaging,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "agent"], // Specify allowed roles
    }
  },
  {
    path: "/shipping",
    name: "Shipping",
    component: Shipping,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager", "shipping"], // Specify allowed roles
    }
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    
    meta: {
      requiresAuth: true,
      allowedRoles: ["manager"], // Specify allowed roles
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {

  const currentState = store.getters.user;


  // const allowedRoutesForManager = ["Home", "Start", "Other", "Release", "Release-Other", "Packaging", "Shipping", "Reports", "Login"];
  // const allowedRoutesForAgent = ["Home","Start", "Other", "Release", "Release-Other", "Login"];
  // const allowedRoutesForShipping = ["Shipping", "Login"];

  console.log("User: ", currentState);

  if(currentState) {
    console.log("user found");
    const userRole = currentState.role;
    const allowedRoles = to.meta.allowedRoles || [];
    console.log("User role: ", userRole);
    if (allowedRoles.includes(userRole)) {
      // User is authorized to access this route
      console.log("routing allowed");
      next();
    } else {
      // User is not authorized to access this route
      if(userRole == "shipping") {
        console.log("routing not allowed");
        next({ name: "Dashboard" }); // Redirect to the home page
      } else {
        console.log("routing not allowed");
        next({ name: "Home" }); 
      }
// Redirect to the home page
    }
  } else {
if (to.name === "Login") {
      // If the destination is the login route, proceed
      next();
    } else {
      // If the destination is not the login route, redirect to login
      next({ name: "Login" });
    }
  }


});

export default router;
